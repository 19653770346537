define("discourse/plugins/discourse-preset-topic-composer/discourse/connectors/after-title-and-category/tag-group-combobox", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    historyStore: (0, _service.inject)(),
    appEvents: (0, _service.inject)(),
    classNames: ["tag-group_wrapper"],
    init() {
      this._super(...arguments);
      const composerHTML = document.querySelector(".composer-fields");
      const selectedButton = this.historyStore.get("newTopicButtonOptions");
      const shouldShowTags = selectedButton?.showTags || false;
      if (!shouldShowTags) {
        composerHTML.classList.add("hide-tag");
      }
      this.appEvents.on("topic:created", () => {
        this.historyStore.set("newTopicButtonOptions", null);
      });
      this.appEvents.on("draft:destroyed", () => {
        this.historyStore.set("newTopicButtonOptions", null);
      });
    },
    get tagGroupList() {
      const selectedButton = this.historyStore.get("newTopicButtonOptions");
      return selectedButton?.tagGroups || [];
    },
    willDestroyElement() {
      this._super(...arguments);
      const composerHTML = document.querySelector(".composer-fields");
      composerHTML.classList.remove("hide-tag");
    }
  });
});