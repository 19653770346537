define("discourse/plugins/discourse-preset-topic-composer/discourse/components/tag-group-combobox", ["exports", "select-kit/components/combo-box", "discourse/plugins/discourse-preset-topic-composer/discourse/components/tag_group_options"], function (_exports, _comboBox, _tag_group_options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    ..._tag_group_options.tagGroupOptions
  });
});