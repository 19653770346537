define("discourse/plugins/discourse-preset-topic-composer/discourse/components/new-topic-dropdown", ["exports", "@ember/owner", "@ember/service", "discourse/models/composer", "select-kit/components/dropdown-select-box"], function (_exports, _owner, _service, _composer, _dropdownSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBox.default.extend({
    classNames: ["new-topic-dropdown"],
    siteSettings: (0, _service.inject)(),
    historyStore: (0, _service.inject)(),
    dropdownButtons: (0, _service.inject)(),
    selectKitOptions: {
      icon: "plus",
      showFullTitle: true,
      autoFilterable: false,
      filterable: false,
      showCaret: true,
      none: "topic.create"
    },
    get content() {
      return this.dropdownButtons.buttons;
    },
    actions: {
      onChange(selectedAction) {
        const composerController = (0, _owner.getOwner)(this).lookup("controller:composer");
        let selectedButton = this.historyStore.get("newTopicButtonOptions");
        if (!selectedButton || selectedAction !== selectedButton.id) {
          const buttons = JSON.parse(this.siteSettings.button_types);
          selectedButton = buttons.find(button => button.id === selectedAction);
          this.historyStore.set("newTopicButtonOptions", selectedButton);
        }
        const selectedButtonCategoryId = selectedButton.categoryId > 0 ? selectedButton.categoryId : null;
        const tags = selectedButton.tags.split(/(?:,|\s)\s*/).filter(Boolean); // remove [''] from tags;
        const options = {
          action: _composer.default.CREATE_TOPIC,
          draftKey: _composer.default.NEW_TOPIC_KEY,
          categoryId: selectedButtonCategoryId ?? this.category?.id ?? null,
          tags
        };
        composerController.open(options);
      }
    }
  });
});